import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";

import Tracker from "@openreplay/tracker";

const tracker = new Tracker({
  projectKey: "gEK3PwiRNWXU6ZMaQXNS",
  ingestPoint: "https://open-replay.tools.imsindia.com/ingest",
  network: {
    capturePayload: true,
  },
});

// import BookingSlotModalTrigger from "./components/web/modal/ModalScreens/BookSlot/BookingSlotModalTrigger";
// import BookingConfirmMain from "./components/web/modal/Confirmation/BookingConfimMain";
import PractoredTest from "./components/web/Test/ProctoredTest/ProctoredTest";
import Prepare from "./pages/prepare/PrepareContainer";
import Test from "./pages/prepare/TestContainer";
// import TakeHomeTestPage from "./components/web/Test/TakeHomeTest/TakeHomeTestPage";
// import PastPaperPage from "./components/web/Test/PastPapers/PastPaperDataHandler";
// import SectionalTestPage from "./components/web/Test/SectionalTest/SectionalData";
import Calendar from "./components/web/cals/cals_main";
import ChannelSeeAll from "./components/web/home/SeeAll/ChannelSeeAll";
import MasterClassSeeAll from "./components/web/home/SeeAll/MasterClassesSeeAll";
import ResumeProgressSellAll from "./components/web/home/SeeAll/ResumeProgressSellAll";

// importing components for login page
import Address from "./components/web/PreLogin/address/Address";
import EducationalDetails from "./components/web/PreLogin/educationDetails/EducationalDetails";
import ExamAttempt from "./components/web/PreLogin/examDetails/ExamAttempt";
import ProfileSlideforFamily from "./components/web/PreLogin/loginforfamily/ProfileSlideforFamily";
import Profile from "./components/web/PreLogin/Main";
import CongratulationsTab from "./components/web/PreLogin/tab_for_congratulations/Congratulations_Tab";
import WorkExperience from "./components/web/PreLogin/workExperience/WorkExperience";
import Register from "./components/web/register/Register";

import Dashboard from "./components/web/mentor/myPlan/dashboard/Dashboard";
// import Login from "./components/web/PreLogin/login/Login";
import { ReactQueryDevtools } from "react-query/devtools";
import ForgotPassword from "./components/web/login/ForgotPassword";
import UserLogin from "./components/web/login/Login";
import Attendance from "./components/web/mentor/Attendance/AttendanceMain";
import NewLogin from "./components/web/newLogin/NewLogin";
import Main from "./components/web/PreLogin/Main";
import Compare from "./components/web/schools/compare/compare";
import CutOffs from "./components/web/schools/cutOffs/CutOff";
import DeadLines from "./components/web/schools/deadlines/Deadlines";
import EligibilityCriteria from "./components/web/schools/eligibilityCriteria/EligibleCriteria";
import SchoolHome from "./components/web/schools/home/Home";
import SchoolDetails from "./components/web/schools/school_details/schoolDetails";
import Gallery from "./components/web/schools/SchoolGallery";
import { Landing } from "./components/web/schools/schools_articles/Landing";
import Events_Landing from "./components/web/schools/schools_events/Events_Landing";
import SelectionCriteria from "./components/web/schools/selectionCriteria/SelectionCriteria";

const Home = lazy(() => import("./pages/home/Home"));
const Detail = lazy(() => import("./pages/detail/Detail"));
// const Test = lazy(() => import("./pages/test/Test"));
const PreparePractice = lazy(() =>
  import("./pages/preparePractice/PreparePractice")
);
const PracticeTest = lazy(() => import("./components/web/testQuestion/Test"));
const Result = lazy(() => import("./pages/preparePractice/Result"));
const DetailedReview = lazy(() =>
  import("./pages/detailReview/DetailedReview")
);
const AreaTestCard = lazy(() => import("./pages/areaTest/AreaTestCard"));
const AreaTest = lazy(() => import("./pages/areaTest/AreaTest"));
const ReviewFromTest = lazy(() => import("./pages/reviewTest/ReviewFromTest"));
const ReviseFromTest = lazy(() => import("./pages/reviseTest/ReviseFromTest"));
//Edited routes for Live class seeall
const SeeAll = lazy(() =>
  import("./components/web/home/SeeAll/LiveClassesSeeAll")
);
const HomeDetail = lazy(() => import("./pages/home/HomeDetail"));
const Notification = lazy(() =>
  import("./components/web/home/notification/NotificationPage")
);
const MyPlan = lazy(() => import("./components/web/myPage/myPlan/MyPlan"));
const MyProfile = lazy(() => import("./components/web/profile/MyProfile"));

{
  /*Schools */
}

import SupportLogin from "./components/web/login/SupportLogin";

// const PrepRecommendation= lazy(() => import("./components/web/mentor/performance/prepRecommendation/PrepRecommendation"));
const ProgressTracker = lazy(() =>
  import("./components/web/performance_module/progressTracker/ProgressTracker")
);
const Predictor = lazy(() =>
  import("./components/web/performance_module/predictor/Predictor")
);
const ScoreSummary = lazy(() =>
  import("./components/web/performance_module/scoreSummary/Main")
);
const QuestionSelection = lazy(() =>
  import(
    "./components/web/performance_module/questionSelection/QuestionSelection"
  )
);

const StudentProgressTracker = lazy(() =>
  import(
    "./components/web/studentPage/performance_module/progressTracker/ProgressTracker"
  )
);
const StudentPredictor = lazy(() =>
  import("./components/web/studentPage/performance_module/predictor/Predictor")
);
const StudentScoreSummary = lazy(() =>
  import("./components/web/studentPage/performance_module/scoreSummary/Main")
);
const StudentQuestionSelection = lazy(() =>
  import(
    "./components/web/studentPage/performance_module/questionSelection/QuestionSelection"
  )
);

const Classes = lazy(() => import("./components/web/home/classes/Classes"));
const CardDetail = lazy(() => import("./components/web/classes/CardDetail"));
const Students = lazy(() =>
  import("./components/web/mentor/myPlan/students/Students")
);
const Slots = lazy(() => import("./components/web/mentor/myPlan/slots/Slots"));
const BookMarks = lazy(() => import("./components/web/bookmarks/BookMarks"));
const MyTasks = lazy(() => import("./components/web/MyTasks/MyTasks"));
const doubts = lazy(() => import("./components/web/myPage/doubts/doubts"));
const Upgrades = lazy(() => import("./components/web/upgrades/Upgrade"));
const ResumeProgressDetail = lazy(() =>
  import("./pages/detail/ResumeProgressDetail")
);
const DetailAnalytics = lazy(() =>
  import("./pages/detailedAnalytics/DetailedAnalytics")
);
const StudentPage = lazy(() => import("./pages/studentPage/StudentPage"));
const StudentList = lazy(() =>
  import("../src/components/web/studentPage/StudentList")
);

import ProtectedRoutes from "./ProectedRoutes";
// import VideoCypherPLayer from "./components/web/global/videoPlayer/VideoCypherPLayer";
import { ToastContainer } from "react-toastify";
import VideoCypherForIMS2 from "./components/web/global/videoPlayer/VideoCypherForIMS2";
import MobileDevicePage from "./pages/mobileRedirect/MobileDevicePage";
import RoutesWrapper from "./RoutesWrapper";
function App() {
  React.useEffect(() => {
    tracker.start();
  }, []);

  return (
    <Suspense fallback="Loading...">
      <Router>
        <Switch>
          <RoutesWrapper
            exact
            path="/not-supported"
            component={MobileDevicePage}
          />
          {/* Routes for login page .. */}
          <RoutesWrapper exact path="/newlogin" component={NewLogin} />
          <RoutesWrapper exact path="/register" component={Register} />
          <RoutesWrapper exact path="/" component={UserLogin} />
          <RoutesWrapper exact path="/support" component={SupportLogin} />

          <RoutesWrapper
            exact
            path="/forgotpassword"
            component={ForgotPassword}
          />

          {/* <RoutesWrapper exact path="/login" component={UserLogin} /> */}
          {/* Route for Student List Page */}
          <RoutesWrapper exact path="/studentList" component={StudentList} />
          {/* Route for Student Page */}
          <RoutesWrapper exact path="/studentPage" component={StudentPage} />
          {/* Routes for Student Performance */}
          <RoutesWrapper
            exact
            path="/studentPage/:topic/Score Summary"
            component={StudentScoreSummary}
          />
          <RoutesWrapper
            exact
            path="/studentPage/:topic/Progress Tracker"
            component={StudentProgressTracker}
          />
          <RoutesWrapper
            exact
            path="/studentPage/:topic/question selection"
            component={StudentQuestionSelection}
          />
          <RoutesWrapper
            exact
            path="/studentPage/:topic/Percentile Predictor"
            component={StudentPredictor}
          />

          {/* Routes for Student performance ends here */}

          <ProtectedRoutes exact path="/login" component={Main} />
          <ProtectedRoutes exact path="/prelogin" component={Profile} />
          <ProtectedRoutes
            exact
            path="/profileLogin_family"
            component={ProfileSlideforFamily}
          />
          <ProtectedRoutes exact path="/address" component={Address} />
          <ProtectedRoutes
            exact
            path="/prelogin_education_details"
            component={EducationalDetails}
          />
          <ProtectedRoutes
            exact
            path="/prelogin_attempt"
            component={ExamAttempt}
          />
          <ProtectedRoutes
            exact
            path="/prelogin_congratulations"
            component={CongratulationsTab}
          />
          <ProtectedRoutes
            exact
            path="/prelogin_experience"
            component={WorkExperience}
          />
          {/* Routes for login page ends here */}

          <ProtectedRoutes exact path="/home" component={Home} />
          <ProtectedRoutes path="/home/notification" component={Notification} />
          {/* Routes for PREPARE */}
          <ProtectedRoutes
            exact
            path="/prepare-:module/detail/:assetName/"
            component={Detail}
          />
          <ProtectedRoutes
            path="/prepare-:module/:topic/"
            component={Prepare}
            exact
          />
          <ProtectedRoutes
            path="/prepare-:module/:topic/:subTopic"
            component={Prepare}
            exact
          />
          <ProtectedRoutes
            exact
            path="/resumeProgress/detail"
            component={ResumeProgressDetail}
          />
          {/* Routes for PREPARE ends here */}

          {/* Routes for TEST */}
          <ProtectedRoutes exact path="/test-:module/:topic" component={Test} />
          <ProtectedRoutes
            exact
            path="/test-:module/:topic/:subTopic"
            component={Test}
          />
          <ProtectedRoutes exact path="/proc" component={PractoredTest} />
          {/* <ProtectedRoutes exact path="/test" component={Test} />
          <ProtectedRoutes exact path="/test/:course/guide" component={Prepare} />
          <ProtectedRoutes exact path="/test/:course/Proctored Test" component={PractoredTest} />
          <ProtectedRoutes exact path="/test/:course/take home tests" component={TakeHomeTestPage} />
          <ProtectedRoutes exact path="/test/:course/past papers" component={PastPaperPage} />
          <ProtectedRoutes exact path="/test/:course/sectional test" component={SectionalTestPage} />
          <ProtectedRoutes exact path="/test/:id/:type/bookingconfirm" component={BookingConfirmMain}/>{" "}
          <ProtectedRoutes exact path="/test/:id/:type/bookingslot" component={BookingSlotModalTrigger} /> */}
          {/* Routes for TEST ends here*/}

          {/* Routes for PREPARE PRACTICE */}
          <ProtectedRoutes
            exact
            path="/preparepractice"
            component={PreparePractice}
          />
          <ProtectedRoutes
            exact
            path="/preparepractice/test"
            component={PracticeTest}
          />
          <ProtectedRoutes
            exact
            path="/preparepractice/result"
            component={Result}
          />
          <ProtectedRoutes
            exact
            path="/preparepractice/detailedreview"
            component={DetailedReview}
          />
          <ProtectedRoutes exact path="/preparetest" component={AreaTestCard} />
          <ProtectedRoutes
            exact
            path="/preparetest/test"
            component={AreaTest}
          />
          {/* Routes for PREPARE PRACTICE ends here */}

          {/* Routes for REVIEW */}
          <ProtectedRoutes
            exact
            path="/reviewtest"
            component={ReviewFromTest}
          />
          <ProtectedRoutes
            exact
            path="/revisetest"
            component={ReviseFromTest}
          />
          {/* Routes for REVIEW ends here */}

          {/* See all */}
          <ProtectedRoutes
            exact
            path="/home/masterclass/:batchId"
            component={MasterClassSeeAll}
          />
          <ProtectedRoutes
            exact
            path="/home/seeallchannels/:learningPathId"
            component={ChannelSeeAll}
          />

          <ProtectedRoutes exact path="/home/featuredblog" component={SeeAll} />
          <ProtectedRoutes exact path="/home/liveclass" component={SeeAll} />
          <ProtectedRoutes
            exact
            path="/home/resumeprogress"
            component={ResumeProgressSellAll}
          />
          <ProtectedRoutes
            exact
            path="/home/last_mile_to_cat"
            component={SeeAll}
          />
          <ProtectedRoutes exact path="/home/examanalysis" component={SeeAll} />
          <ProtectedRoutes exact path="/home/futureyou" component={SeeAll} />
          <ProtectedRoutes
            exact
            path="/home/homedetail/:assetName/"
            component={HomeDetail}
          />
          <ProtectedRoutes exact path="/Home/Calendar" component={Calendar} />

          {/*For My profile */}
          <ProtectedRoutes exact path="/myprofile" component={MyProfile} />

          {/*Schools */}
          <ProtectedRoutes exact path="/school/home" component={SchoolHome} />
          <ProtectedRoutes exact path="/school/articles" component={Landing} />
          <ProtectedRoutes
            exact
            path="/school/events"
            component={Events_Landing}
          />
          <ProtectedRoutes
            exact
            path="/school/selection criteria"
            component={SelectionCriteria}
          />
          <ProtectedRoutes
            exact
            path="/school/eligibility criteria"
            component={EligibilityCriteria}
          />
          <ProtectedRoutes exact path="/school/cutoffs" component={CutOffs} />
          <ProtectedRoutes
            exact
            path="/school/deadlines"
            component={DeadLines}
          />
          <ProtectedRoutes exact path="/school/compare" component={Compare} />
          <ProtectedRoutes
            exact
            path="/school/details"
            component={SchoolDetails}
          />
          <ProtectedRoutes exact path="/school/gallery" component={Gallery} />

          {/* Routes for Performance */}
          <ProtectedRoutes
            exact
            path="/performance-:module/:topic/Score Summary"
            component={ScoreSummary}
          />
          <ProtectedRoutes
            exact
            path="/performance-:module/:topic/Progress Tracker"
            component={ProgressTracker}
          />
          <ProtectedRoutes
            exact
            path="/performance-:module/:topic/question selection"
            component={QuestionSelection}
          />
          <ProtectedRoutes
            exact
            path="/performance-:module/:topic/Percentile Predictor"
            component={Predictor}
          />
          {/* Routes for performance ends here */}
          <ProtectedRoutes exact path="/Classes" component={Classes} />
          <ProtectedRoutes
            exact
            path="/Classes/LIVE/:id"
            component={CardDetail}
          />

          {/*Routes for Myplan Mentor*/}
          <ProtectedRoutes
            exact
            path="/myPlan/dashboard"
            component={Dashboard}
          />
          <ProtectedRoutes exact path="/myPlan/slots" component={Slots} />
          <ProtectedRoutes exact path="/myplan/students" component={Students} />

          <ProtectedRoutes
            exact
            path="/mentor/attendance"
            component={Attendance}
          />

          {/*Routes for Mypage Student */}
          <ProtectedRoutes exact path="/mypage/myplan" component={MyPlan} />
          <ProtectedRoutes
            exact
            path="/myPage/bookmarks"
            component={BookMarks}
          />
          <ProtectedRoutes exact path="/myPage/myTasks" component={MyTasks} />
          <ProtectedRoutes exact path="/myPage/doubts" component={doubts} />
          <ProtectedRoutes exact path="/upgrade" component={Upgrades} />
          {/* Routes for DetailAnalytics */}
          <ProtectedRoutes
            exact
            path="/test/Detailed Analytics/:testName"
            component={DetailAnalytics}
          />

          {/* Route for video Cypher  */}
          <RoutesWrapper
            exact
            path="/ims/videoplayer/:imspin/:videoid/:videoType"
            component={VideoCypherForIMS2}
          />
        </Switch>
      </Router>
      <ToastContainer
        icon={false}
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        theme="dark"
        position="top-right"
        bodyStyle={{ zIndex: 500 }}
        className={"top-10"}
      />
      <ReactQueryDevtools initialIsOpen={false} />
    </Suspense>
  );
}

export default App;
export { tracker as OpenReplayTracker };
