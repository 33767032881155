import React from "react";
import AccordianBar from "./AccordianBar";
import PropTypes from "prop-types";
import AccordianBody from "./AccordianBody";
import AccordianBodyWithScroll from "./AccordianBodyWithScroll";
import { useDispatch, useSelector } from "react-redux";
import { setAccordianId } from "../../../../redux/features/assetDetail/assetDetailSlice";
// import uuid from "react-uuid";

function Accordian({
  data,
  courseId,
  prepareListRefetch,
  setParentIdForNextLearning,
  setLearningPathIdForNext,
  testCerebryData,
}) {
  const dispatch = useDispatch();
  const accordianId = useSelector((state) => state.assetDetail.accordianId);
  const handleOpenCloseAccordian = () => {
    // localStorage.setItem("breadcrumb2", data?.name);
    if (accordianId === data.learningPathId) dispatch(setAccordianId(null));
    else dispatch(setAccordianId(data.learningPathId));
  };
  const subTiers = (child) => child.assetPath === "0";
  const doSubTiersExist = data.children?.some(subTiers);

  return (
    <div>
      <AccordianBar
        isAccordianOpen={data.learningPathId === accordianId}
        handleOpenCloseAccordian={handleOpenCloseAccordian}
        name={data.name}
        noOfUnits={data.children.length}
      />
      {data.learningPathId === accordianId &&
        (doSubTiersExist ? (
          <AccordianBodyWithScroll
            data={data}
            assets={data.children}
            title={data.name}
            courseId={courseId}
            prepareListRefetch={prepareListRefetch}
            setParentIdForNextLearning={setParentIdForNextLearning}
            setLearningPathIdForNext={setLearningPathIdForNext}
            testCerebryData={testCerebryData}
          />
        ) : (
          <AccordianBody
            data={data}
            assets={data.children}
            testCerebryData={testCerebryData}
            parentId={data.learningPathId}
            title={data.name}
          />
        ))}
    </div>
  );
}

Accordian.propTypes = {
  data: PropTypes.object,
  moduleId: PropTypes.string,
  courseId: PropTypes.string,
  prepareListRefetch: PropTypes.func,
  setParentIdForNextLearning: PropTypes.func,
  setLearningPathIdForNext: PropTypes.func,
  testCerebryData: PropTypes.any,
};

export default Accordian;
