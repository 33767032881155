import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { loginAPI_apirequest } from "../../../API/api";
import { useQuery } from "react-query";
import { endpoint } from "../../../API/config.jsx";
import { useHistory } from "react-router-dom";
import loginImage from "../../../assets/images/loginImage.png";
import imslogo from "../../../assets/icons/ims-logo.svg";
// import maskImage from "../../../assets/images/maskImage.png";
import CancelIcon from "../../../assets/icons/cancel-icon-rounded.svg";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import SwiperCore, { Navigation, Autoplay, Pagination, Virtual } from "swiper";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.css";
import "swiper/swiper-bundle.min.css";
import "./swiper.css";
import OtpModalTrigger from "../PreLogin/login/OtpModalTrigger";
SwiperCore.use([Navigation, Autoplay, Pagination, Virtual]);
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentHeaderData } from "../../../redux/features/header/headerSlice";
import {
  changeCourse,
  saveLoginDetails,
  loginDetails,
  setChannelId,
  saveStudentData,
  setIsParent,
} from "../../../redux/features/login/LoginSlice";
import Loader from "../global/loader/Loader";
import OtpModal from "./OtpModal";
import { OpenReplayTracker } from "../../../App.js";

const swiperData = [
  {
    id: 1,
    title: "Experience in shaping success stories",
    para: "IMS’s experienced mentorship aids students in achieving high scores best suited to their objectives, aspirations, and skill sets.",
  },
  {
    id: 2,
    title: "We believe every student's journey is unique",
    para: "IMS creates tailor-made learning paths by recognizing their unique traits and capabilities.",
  },
  {
    id: 3,
    title: "There is limitless possibility",
    para: "IMS ensures that its students are not just exam-ready but also to embark on their next academic journey.",
  },
];
let signUpRedirectionURL =
  "https://www.imsindia.com/zero-fee-enrollment/?degree=&txtlimacampaign=&utm_source=&utm_medium=&utm_campaign=&utm_term=&utm_content";

function Login() {
  const dispatch = useDispatch();
  let history = useHistory();
  const LoginDetails = useSelector(loginDetails);
  const [activeTab, setActiveTab] = useState("student");
  const [userName, setUserName] = useState("");
  const [isNextClicked, setIsNextClicked] = useState(false);
  const [password, setPassword] = useState("");
  const [isNewStudent, setIsNewStudent] = useState(false);
  const [isPasswordForgotten, setIsPasswordForgotten] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isLoginError, setIsLoginError] = useState(false);
  const [isgetOTPClicked, setIsgetOTPClicked] = useState(false);
  const [verificationDetails, setVerificationDetails] = React.useState();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSaveandContinueClicked, setIsSaveandContinueClicked] =
    useState(false);
  const [mobileNumberOrEmail, setMobileNumberOrEmail] = useState("");
  const [isShowModal, setIsShowModal] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [isSaveandContinueError, setIsSaveandContinueError] = useState(false);
  const [isWrongPhoneNumber, setIsWrongPhoneNumber] = useState(false);
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      refetchLoginVerification();
      event.preventDefault();
    }
  };
  const handleEnterPassword = (event) => {
    if (event.key.toLowerCase() === "enter") {
      event.preventDefault();
      refetchLogin();
      setShowLoader(true);
    }
  };
  const handleSaveAndContinue = () => {
    if (newPassword && newPassword == confirmPassword) {
      setIsSaveandContinueClicked(true);
    } else {
      setIsSaveandContinueError(true);
    }
  };
  const setNewPasswd = () => {
    return loginAPI_apirequest({
      url: `${endpoint.createNewPassword.addr}`,
      method: "post",

      headers: {
        "access-token": LoginDetails.token,
      },
      data: {
        studentEmail:
          verificationDetails.studentEmail || verificationDetails.emailId,
        studentImsPin: verificationDetails.studentImsPin,
        enterNewPassword: newPassword,
      },
    });
  };
  const { isSuccess, isLoading: iscreatePswdLoading } = useQuery(
    [endpoint.createNewPassword.key],
    setNewPasswd,
    {
      enabled: !!isSaveandContinueClicked,
      retry: false,
    }
  );
  const handleChangeUserName = (e) => {
    let value = e.target.value;
    if (value.match(/^[0-9]+$/) && value?.length !== 10) {
      setIsWrongPhoneNumber(true);
    } else {
      setIsWrongPhoneNumber(false);
    }
    setUserName(e.target.value);
  };
  const checkIsStudentOrParent = () => {
    return loginAPI_apirequest({
      url: `${endpoint.loginVerification.addr}`,
      method: "post",
      data: {
        studentImsPin: userName?.trim(),
        loginType: activeTab,
      },
    });
  };
  const {
    refetch: refetchLoginVerification,
    isError: isUserNotFound,
    isLoading: isVerificationApiLoading,
  } = useQuery([endpoint.loginVerification.key], checkIsStudentOrParent, {
    enabled: false,
    retry: false,
    onSuccess: (res) => {
      setIsNewStudent(res.data.data.newStudent);
      setVerificationDetails(res.data.data);
      setIsNextClicked(true);
    },
    onError: () => {
      setIsNextClicked(true);
    },
  });
  const handleClick = (e) => {
    e.preventDefault();
    refetchLogin();
    setShowLoader(true);
  };
  const getLoginDetails = (userName, password) => {
    return loginAPI_apirequest({
      url: `${endpoint.studentLoginCheck.addr}`,
      method: "post",
      headers: {
        "access-token": LoginDetails.token,
      },
      data: {
        studentemail: userName.includes("@") ? userName?.trim() : "",
        studentimspin: userName.includes("@") ? "" : userName?.trim(),
        studentpassword: activeTab == "student" ? password : "",
        loginmode: "1",
        otp: activeTab == "parent" ? otpValue : "",
        loginType: activeTab,
      },
    });
  };

  const { isLoading: isLoginLoading, refetch: refetchLogin } = useQuery(
    [endpoint.studentLoginCheck.key],
    () => getLoginDetails(userName, password),
    {
      retry: false,
      enabled: false,
      onSuccess: (res) => {
        setShowLoader(false);
        let data = res.data.data;
        console.log(data);
        if (activeTab == "student") {
          dispatch(saveLoginDetails(data));
          // dispatching the channelId after login
          dispatch(setChannelId(data?.channelData?.moduleId));
          let courseIdNVariantId =
            data.lastLoggedInCourseId.toString() +
            "_" +
            data.lastLoggedInVariantId.toString();
          let courseId = data.lastLoggedInCourseId.toString();
          let name = data.lastloggedInCourseName;
          let variantId = data.lastLoggedInVariantId;
          dispatch(
            changeCourse({ courseIdNVariantId, courseId, name, variantId })
          );
          // TODO! Replace Local storage with cookies
          let loginInfo = {
            adminemail: res.data.data.studentEmail
              ? res.data.data.studentEmail
              : "",
            adminimspin: res.data.data.student_imspin
              ? res.data.data.student_imspin
              : "",
            txtpassword: password,
            token: res.data.data.token,
          };


          // Identify users by user ID
          console.log("setting user id");
          OpenReplayTracker.setUserID(loginInfo.adminimspin);

          // Sending Cookie to CMS

          const EncryptedLoginInfo = CryptoJS.AES.encrypt(
            JSON.stringify(loginInfo),
            process.env.REACT_APP_COOKIE_SECRET
          ).toString();

          Cookies.set("loginInfo", EncryptedLoginInfo, {
            domain: ".imsindia.com",
            secure: true,
          });

          localStorage.setItem("loginInfo", EncryptedLoginInfo);
          dispatch(setCurrentHeaderData(res.data.data.headerModuleData));
          //TODO  TO BE REMOVED AFTER  OTP MODAL IS LINKED
          history.push("/home");
        } else {
          dispatch(saveStudentData(data?.studentData));
          dispatch(setIsParent(true));
          history.push("/studentList");
        }
      },
      onError: () => {
        setIsLoginError(true);
        setShowLoader(false);
      },
    }
  );

  {
    /********************************For New user verifying OTP to set password****************************************************************/
  }
  const getOtpVerification = () => {
    return loginAPI_apirequest({
      url: `${endpoint.newloginOtpVerification.addr}`,
      method: "post",

      headers: {
        "access-token": LoginDetails.token,
      },
      data: {
        studentEmail: verificationDetails.emailId,
        studentImsPin: verificationDetails.studentImsPin,
        otp: otpValue,
      },
    });
  };
  const { refetch: newLoginVerificationRefetch, isError: isNewLoginError } =
    useQuery([endpoint.newloginOtpVerification.key], getOtpVerification, {
      enabled: false,
      retry: false,
      onSuccess: () => {
        setIsShowModal(false);
        setIsNewStudent(false);
        setIsgetOTPClicked(true);
        setIsPasswordForgotten(true);
      },
    });

  useEffect(() => {
    if (activeTab == "student") dispatch(setIsParent(false));
    else dispatch(setIsParent(true));
  }, [activeTab]);

  React.useEffect(() => {
    if (LoginDetails?.student_imspin && activeTab == "student") {
      history.push("/home");
    }
  }, []);
  const last4Digits = verificationDetails?.mobileNumber?.toString().slice(-4);
  const maskedNumber = last4Digits?.padStart(
    verificationDetails?.mobileNumber?.length,
    "*"
  );
  return (
    <div className="flex font-ibm w-100%">
      {/*******************************LEFT SECTION ***********************************/}
      <div className="w-[60%] max-h-screen">
        <img
          className="w-[100%] h-[100%]"
          src={loginImage}
          alt="login-image"
          loading="lazy"
        />
        <div className="w-[60%] h-[50%] relative bottom-80 left-24">
          {/* <img className="w-[100%] " src={maskImage} alt="mask-image" loading="lazy"/> */}
          <div className="h-auto relative bottom  space-y-5">
            {/* removed Sign Up static button */}
            <button
              className="bg-white text-black text-base font-medium px-8 py-2 rounded-xl ml-10"
              onClick={() =>
                window.open(signUpRedirectionURL, "_blank", "noreferrer")
              }
            >
              Sign Up
            </button>

            <section className="w-[350px] ml-10">
              <Swiper
                slidesPerView={1}
                pagination={true}
                autoplay
                virtual
                itemType="fractionx"
              >
                {swiperData.map((item) => (
                  <SwiperSlide key={item.id} className="space-y-5  mb-8">
                    <h1 className="text-3.5xl text-white  font-extralight">
                      {item.title}
                    </h1>
                    <p className="text-lg text-white font-light">{item.para}</p>
                  </SwiperSlide>
                ))}
              </Swiper>
            </section>
          </div>
        </div>
      </div>
      {/********************************RIGHT SECTION ***********************************/}
      <div className="w-[40%]">
        <section className="w-full h-auto">
          {/* <div className="w-[100%]"> */}
          <div className="grid grid-cols-1 place-content-center justify-items-center py-5">
            <div className="justify-start">
              <img
                className="w-20 h-12  my-10"
                src={imslogo}
                alt="ims-logo"
                loading="lazy"
              />
              {/********************************Asking Users phone number/Pin for login ***********************************/}
              {!isNextClicked && !isgetOTPClicked ? (
                <div className="space-y-5 font-ibm grid grid-cols-1 justify-center w-full align-middle">
                  <div className="w-full ">
                    <span className="font-semibold text-4xl">Login to </span>
                    <span className="font-semibold text-4xl text-primary-200">
                      myIMS
                    </span>
                  </div>
                  <div className="font-ibm flex my-6 ">
                    <div
                      className={`px-6 py-4 rounded-2xl shadow-outer mr-4 ${
                        activeTab === "student"
                          ? "text-white bg-primary-300 font-medium "
                          : " text-secondary-600"
                      } cursor-pointer `}
                      onClick={() => handleTabClick("student")}
                    >
                      Student
                    </div>
                    <div
                      className={`px-6 py-4 rounded-2xl shadow-outer mr-4 ${
                        activeTab === "parent"
                          ? "text-white bg-primary-300 font-medium "
                          : " text-secondary-600"
                      } cursor-pointer `}
                      onClick={() => handleTabClick("parent")}
                    >
                      Parent
                    </div>
                  </div>
                  {activeTab == "student" ? (
                    <div className="w-[423px]">
                      {/****************** for student and mentor ***********************/}
                      <span className="text-xl">
                        Enter your registered Mobile Number / IMS Pin
                      </span>
                      <div className="grid grid-cols-1 space-y-3">
                        <input
                          value={userName}
                          onKeyDown={handleEnter}
                          onChange={(e) => handleChangeUserName(e)}
                          type="text"
                          className="border-2 border-gray-200 rounded-2xl py-3 px-3 focus:outline-none mt-5"
                          placeholder="Enter Mobile Number / IMS Pin"
                        />
                        {isWrongPhoneNumber && (
                          <p className=" text-red-500 text-lg">
                            Please enter valid Mobile Number
                          </p>
                        )}
                        {isVerificationApiLoading ? (
                          <Loader />
                        ) : (
                          <button
                            disabled={userName ? false : true}
                            onClick={refetchLoginVerification}
                            // onFocus={handleTabClick}
                            className={`w-full px-24 py-3 mt-2 rounded-xl text-white text-lg font-medium ${
                              userName ? "bg-primary-200" : "bg-[#8E9EAB]"
                            } `}
                          >
                            Next
                          </button>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="w-[423px]">
                      {/****************** for Parent ***********************/}
                      <span className="text-xl">
                        Enter registered parent’s mobile number
                      </span>
                      <div className="grid grid-cols-1 space-y-3">
                        <input
                          value={userName}
                          onKeyDown={handleEnter}
                          onChange={(e) => handleChangeUserName(e)}
                          type="text"
                          className="border-2 border-gray-200 rounded-2xl py-3 px-3 focus:outline-none mt-5"
                          placeholder="Enter Mobile Number "
                        />
                        {isWrongPhoneNumber && (
                          <p className=" text-red-500 text-base">
                            Please enter valid Mobile Number
                          </p>
                        )}

                        {isVerificationApiLoading ? (
                          <Loader />
                        ) : (
                          <button
                            disabled={userName ? false : true}
                            onClick={refetchLoginVerification}
                            // onFocus={handleTabClick}
                            className={`w-full px-24 py-3 mt-2 rounded-xl text-white text-lg font-medium ${
                              userName ? "bg-primary-200" : "bg-[#8E9EAB]"
                            } `}
                          >
                            Next
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ) : activeTab == "student" ? (
                <div className=" mt-6 text-xl">
                  {/********************************If Mobile number is not registered***********************************/}
                  {isUserNotFound ? (
                    <div className="flex flex-col space-y-3  w-[423px]">
                      <p className="text-primary-500 ">
                        We could not find your details in our records.
                      </p>
                      <p>
                        Please verify the details you have entered or enroll in
                        our
                        <a
                          className="text-primary-800"
                          href="https://www.imsindia.com/zero-fee-enrollment/?degree=&txtlimacampaign=&utm_source=&utm_medium=&utm_campaign=&utm_term=&utm_content="
                        >
                          {" "}
                          Zero-fee program.
                        </a>
                      </p>
                    </div>
                  ) : isNewStudent ? (
                    !isShowModal ? (
                      <div className="flex flex-col space-y-3 w-[423px] mt-5">
                        {/******************************If Mobile number is  registered and password not set*************************************/}
                        <p className="text-primary-1000 text-4xl font-semibold mb-6">
                          Welcome to myIMS.
                        </p>
                        <p>Please set the password for your profile.</p>
                        <button
                          onClick={() => setIsShowModal(true)}
                          className="w-full px-24 py-3 mt-2 rounded-xl text-white text-lg font-medium bg-primary-200"
                        >
                          Proceed
                        </button>
                      </div>
                    ) : (
                      <OtpModal
                        setIsShowModal={setIsShowModal}
                        generateNewOtp={refetchLoginVerification}
                        title="Authenticate via OTP to set up your myIMS Account"
                        description={`An OTP has been sent to your mobile number ${maskedNumber} and registered email address.`}
                        buttonText="Verify OTP"
                        setOtpValue={setOtpValue}
                        error={isNewLoginError}
                        refetch={newLoginVerificationRefetch}
                      />
                    )
                  ) : !isPasswordForgotten ? (
                    <div className=" w-[423px] flex flex-col">
                      <div>
                        <span className="font-semibold text-4xl">
                          Login to{" "}
                        </span>
                        <span className="font-semibold text-4xl text-primary-200">
                          myIMS
                        </span>
                      </div>
                      {/********************************If Mobile number is  registered and password is set ***********************************/}
                      <span className="text-xl mt-6">Enter your Password</span>
                      <form
                        className="grid grid-cols-1 space-y-3 mt-4"
                        onSubmit={handleClick}
                      >
                        <input
                          autoFocus
                          value={password}
                          onKeyDown={handleEnterPassword}
                          onChange={(e) => setPassword(e.target.value)}
                          type="password"
                          className="border-2 border-gray-200 rounded-2xl py-3 px-3 focus:outline-none"
                          placeholder={"Password"}
                        />
                        <div className="flex justify-between">
                          <span
                            className="underline text-base font-medium mt-4 cursor-pointer"
                            onClick={() => history.push("/forgotpassword")}
                          >
                            Forgot password
                          </span>

                          <div className="flex flex-col ">
                            {showLoader ? (
                              <Loader />
                            ) : (
                              <Link to="/prelogin">
                                <button
                                  disabled={
                                    userName && password != "" ? false : true
                                  }
                                  onClick={handleClick}
                                  onFocus={handleClick}
                                  className={`w-full px-24 py-3 mt-2 rounded-xl text-white text-lg font-medium ${
                                    userName && password != ""
                                      ? "bg-primary-200"
                                      : "bg-[#8E9EAB]"
                                  } `}
                                >
                                  Login
                                </button>
                              </Link>
                            )}
                            {isLoginError && !isLoginLoading && (
                              <p className="text-red-500 text-base font-medium mt-2">
                                Incorrect Password
                              </p>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  ) : (
                    <div>
                      {!isgetOTPClicked ? (
                        <div>
                          <span className="text-xl font-medium">
                            Enter your registered Email ID or IMS Pin to receive
                            an OTP
                          </span>
                          <form className="grid grid-cols-1 space-y-3">
                            <input
                              value={mobileNumberOrEmail}
                              onChange={(e) =>
                                setMobileNumberOrEmail(e.target.value)
                              }
                              type="text"
                              className="border-2 border-gray-200 rounded-2xl py-3 px-3 focus:outline-none mt-5"
                              placeholder="Registered Email-Id/ IMS Pin"
                            />
                          </form>
                          <div className="flex flex-col ">
                            {isLoginLoading ? (
                              <Loader />
                            ) : (
                              <OtpModalTrigger
                                isgetOTPClicked={isgetOTPClicked}
                                setIsgetOTPClicked={setIsgetOTPClicked}
                                mobileNumberOrEmail={mobileNumberOrEmail}
                                setDetails={setVerificationDetails}
                              />
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="w-[423px]">
                          <span className="text-xl font-medium mb-3">
                            Create New Password
                          </span>
                          <form
                            className="grid grid-cols-1 space-y-3 mt-3"
                            onSubmit={handleClick}
                          >
                            <input
                              value={newPassword}
                              onChange={(e) => {
                                setIsSaveandContinueError(false);
                                setNewPassword(e.target.value);
                              }}
                              type="password"
                              className={`border-2 border-gray-200 rounded-2xl py-3 px-3 focus:outline-none mt-5
                                     ${
                                       isSaveandContinueError
                                         ? "border-2 border-red-500 rounded-2xl py-3 px-3 focus:outline-none mt-5"
                                         : ""
                                     }`}
                              placeholder="Enter New Password"
                            />
                            <input
                              value={confirmPassword}
                              onChange={(e) => {
                                setIsSaveandContinueError(false);
                                setConfirmPassword(e.target.value);
                              }}
                              type="password"
                              className={`border-2 border-gray-200 rounded-2xl py-3 px-3 focus:outline-none mt-5"
                                  placeholder="Enter New Password ${
                                    isSaveandContinueError
                                      ? "border-2 border-red-500 rounded-2xl py-3 px-3 focus:outline-none mt-5"
                                      : ""
                                  }`}
                              placeholder="Re-Enter New Password"
                            />

                            {!isSuccess ? (
                              <div className="flex flex-col ">
                                {iscreatePswdLoading ? (
                                  <Loader />
                                ) : (
                                  <button
                                    disabled={
                                      !isSaveandContinueError ? false : true
                                    }
                                    onClick={handleSaveAndContinue}
                                    className={`w-full px-24 py-3 rounded-xl text-white text-lg font-medium ${
                                      !isSaveandContinueError
                                        ? "bg-primary-200"
                                        : "bg-[#8E9EAB]"
                                    } `}
                                  >
                                    Save and Continue
                                  </button>
                                )}
                              </div>
                            ) : (
                              <div className="flex flex-col items-center my-3 ">
                                <p className="text-primary-1000 text-2xl font-semibold mb-6">
                                  Password created Successfully
                                </p>
                                <button
                                  className="bg-primary-100 text-white rounded-2xl font-medium w-44 h-14 flex items-center justify-center"
                                  onClick={() => window.location.reload(false)}
                                >
                                  Proceed
                                </button>
                              </div>
                            )}
                            {isSaveandContinueError && (
                              <div className="flex h-20">
                                <img
                                  src={CancelIcon}
                                  alt="cancel"
                                  className="w-4 h-4 mt-2"
                                  loading="lazy"
                                />
                                <p className="text-red-500 text-sm font-normal mt-2">
                                  Entered Password and confirm password does not
                                  match, Try Again
                                </p>
                              </div>
                            )}
                          </form>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div className=" mt-6 text-xl">
                  {/********************************If Mobile number is not registered***********************************/}
                  {isUserNotFound ? (
                    <div className="flex flex-col space-y-3">
                      <p className="text-primary-500 ">
                        The mobile number is not linked to any student in our
                        system.
                      </p>
                      <p>
                        Please raise a request{" "}
                        <a
                          className="text-primary-800"
                          href="https://support.imsindia.com/"
                        >
                          {" "}
                          here{" "}
                        </a>{" "}
                        to link your mobile with the student account.
                      </p>
                    </div>
                  ) : (
                    <OtpModal
                      setIsShowModal={setIsShowModal}
                      generateNewOtp={refetchLoginVerification}
                      title="Login to myIMS for Parents"
                      description={` An OTP has been sent to your mobile number ${maskedNumber} and registered email address.`}
                      buttonText="Verify OTP"
                      setOtpValue={setOtpValue}
                      error={isLoginError}
                      refetch={refetchLogin}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Login;
