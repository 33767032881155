import propTypes from "prop-types";
import React from "react";
import { Redirect, Route, useHistory } from "react-router-dom";

function RoutesWrapper({ component: Comp, ...rest }) {
  const history = useHistory();

  const isForcedView = localStorage.getItem("forced-view");
  const shouldRedirect =
    window.innerWidth < 750 &&
    history.location?.pathname !== "/not-supported" &&
    !isForcedView;

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return !shouldRedirect ? (
          <Comp />
        ) : (
          <Redirect
            to={{
              pathname: "/not-supported",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}
RoutesWrapper.propTypes = {
  component: propTypes.oneOfType([propTypes.elementType || propTypes.func]),
};
export default RoutesWrapper;
