import React, { useEffect } from "react";
import { loginDetails } from "./redux/features/login/LoginSlice";
import { useSelector } from "react-redux";
import { Route, Redirect, useHistory } from "react-router-dom";
import propTypes from "prop-types";

function PrivateRoute({ component: Comp, ...rest }) {
  const LoginDetails = useSelector(loginDetails);
  const isParent = useSelector((state) => state.login.isParent);
  const history = useHistory();

  useEffect(() => {
    if (history.location && history.location.pathname !== "/") {
      // Create the CSS link element
      var cssLink = document.createElement("link");
      cssLink.href = "//www.imsindia.com/WBM/css/ubm.css?ver=2.68";
      cssLink.rel = "stylesheet";
      document.head.appendChild(cssLink);

      // Create the first JavaScript script element
      var script1 = document.createElement("script");
      script1.src = "//www.imsindia.com/WBM/js/ubm-jsonp.js?ver=2.68";
      document.body.appendChild(script1);

      // Create the second JavaScript script element
      var script2 = document.createElement("script");
      script2.src = "//in.fw-cdn.com/31292575/608260.js";
      script2.setAttribute("chat", "true");
      document.body.appendChild(script2);
    }
  }, [history.location?.pathname]);

  const isForcedView = localStorage.getItem("forced-view");
  const shouldRedirect =
    window.innerWidth < 750 &&
    history.location?.pathname !== "/not-supported" &&
    !isForcedView;

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return isParent || LoginDetails.token ? (
          <Comp />
        ) : (
          <Redirect
            to={{
              pathname: shouldRedirect ? "/not-supported" : "/",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}
PrivateRoute.propTypes = {
  component: propTypes.oneOfType([propTypes.elementType || propTypes.func]),
};
export default PrivateRoute;
