import React from "react";
import AppleStore from "../../assets/icons/apple-store.svg";
import PlayStore from "../../assets/icons/play-store.svg";
import logo from "../../assets/icons/ims-logo.svg";
import { useHistory } from "react-router-dom";
const MobileDevicePage = () => {
  const history = useHistory();

  return (
    <div className="grid place-items-center p-5 h-[100dvh]">
      <div className="flex flex-col">
        <img
          className="h-20 w-20 cursor-pointer mx-auto"
          src={logo}
          alt="My IMS Logo"
          onClick={() => history.push("/home")}
          loading="lazy"
        />
        <div className="p-4 rounded-xl border border-primary-100 text-center">
          <h3 className="font-ibm text-xl text-center font-semibold text-secondary-600 mb-5">
            Experience MyIMS Better on Our App
          </h3>
          <p>
            For a smoother, faster, and more optimized experience, please
            download the MyIMS mobile app. Access all your featuers with ease
          </p>
        </div>

        <div
          data-tip
          data-for="mobile-tip"
          className="flex items-center justify-center"
        >
          <a
            href="https://play.google.com/store/apps/details?id=com.india.ims.imsindia3&hl=en_IN"
            target="_blank"
            rel="noreferrer"
          >
            <img
              width={180}
              height={40}
              className="cursor-pointer"
              src={PlayStore}
              alt="PlayStore"
              loading="lazy"
            />
          </a>
          <a
            href="https://apps.apple.com/in/app/myims-3-0-2024-version/id1660922261"
            target="_blank"
            rel="noreferrer"
          >
            <img
              width={180}
              height={40}
              className="cursor-pointer"
              src={AppleStore}
              alt="AppleStore"
              loading="lazy"
            />
          </a>
        </div>
      </div>

      <button
        onClick={() => {
          localStorage.setItem("forced-view", "true");
          history.push("/home");
        }}
        className="underline text-primary-200"
      >
        Continue Anyway
      </button>
    </div>
  );
};

export default MobileDevicePage;
